<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.915 22.915"><g stroke="#000" transform="translate(-1678.7 -216.1)" stroke-width=".8"><circle cx="11.057" cy="11.057" r="11.057" fill="none" stroke-linecap="round" stroke-miterlimit="10" transform="translate(1679.1 216.5)"/><path d="M1695.244 223.801h-2.838v-1.092a.71.71 0 00-.709-.709h-3.082a.71.71 0 00-.709.709v1.092h-2.838a.71.71 0 00-.709.709v7.18a.71.71 0 00.709.709h10.176a.71.71 0 00.709-.709v-7.183a.71.71 0 00-.709-.706zm-6.865-1.092a.237.237 0 01.237-.236h3.082a.237.237 0 01.236.236v1.092h-3.554zm-3.311 1.565h10.176a.237.237 0 01.237.236v2.727a22.05 22.05 0 01-4.211.615v-1.062a.236.236 0 00-.236-.236h-1.754a.237.237 0 00-.237.236v1.063a21.993 21.993 0 01-4.211-.616v-2.727a.237.237 0 01.236-.236zm5.729 2.753v1.775a.641.641 0 11-1.281 0v-1.775zm4.448 4.9h-10.176a.237.237 0 01-.237-.236v-3.97a22.485 22.485 0 004.211.6v.475a1.114 1.114 0 002.227 0v-.475a22.532 22.532 0 004.211-.6v3.969a.237.237 0 01-.237.24z"/></g></svg>
</template>

<script>
  export default {
    name : 'MeepIconPersonal'
  };
</script>
